import * as React from "react";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
//import Editor from "../../components/Editor/Editor";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { AddKimsSelfImageReplyApi } from "src/DAL/member/member";
import { project_name } from "src/config/config";
import TinyEditor from "src/components/ckeditor/Ckeditor";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  button: {
    backgroundColor: "black",
    color: "white",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
}));
const Input = styled("input")({
  display: "none",
});

export default function AddSelfImageKimReply({ goalDetail }) {
  const classes = useStyles();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [personName, setPersonName] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = React.useState({});
  const [audioFile, setAudio] = React.useState();
  const [groups, setGroups] = React.useState([]);
  const [date, setDate] = React.useState(new Date());
  const [programName, setProgramName] = React.useState("");
  const [vaultName, setVaultName] = React.useState("");
  const [detailDescriptionCk, setDetailDescriptionCk] = React.useState("");

  const [inputs, setInputs] = React.useState({
    title: "",
    zoomLink: "",
    status: "",
    recordingStatus: "true",
    program: "",
    image: {},
    password: "",
    areaCode: "",
    videoUrl: "",
    groups: [],
    members: [],
    short_description: "",
    detailed_description: "",
    video_show_Status: "",
  });

  const audioFileChange = (e) => {
    setAudio(e.target.files[0]);
  };

  const handldeDeleteAudio = () => {
    setAudio();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("member_id", params.id);
    formData.append("message", detailDescriptionCk);
    formData.append("module_type", "self_image");
    if (audioFile) {
      formData.append("audio_file", audioFile);
    }

    setIsLoading(true);
    const result = await AddKimsSelfImageReplyApi(formData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      goalDetail();
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 mt-5">
          <div className="col-12 mt-5">
            <h4>
              {project_name == "basic-dev"
                ? "Kim's Reply"
                : project_name == "dynamite-lifestyle-dev"
                ? "Kim's Reply"
                : project_name == "danielle-dev"
                ? "Danielle's Reply *"
                : project_name == "hina-khan-dev"
                ? "Hina's Reply"
                : project_name == "dev-feature"
                ? "Kim's Reply"
                : project_name == "pgi-dev"
                ? "Kim's Reply"
                : "Kim's Reply"}
            </h4>
            <TinyEditor
              setDetailDescription={setDetailDescriptionCk}
              detailDescriptionCk={detailDescriptionCk}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Upload Audio</p>
                <FormHelperText className="pt-0">
                  Audio mp3 (max 200mb)
                </FormHelperText>
              </div>
              <div className="col-2">
                {audioFile && (
                  <DeleteIcon
                    onClick={handldeDeleteAudio}
                    className="mt-3 icon-color"
                  />
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="audio">
                  <Input
                    accept="audio/mp3,audio/*;capture=microphone"
                    id="audio"
                    multiple
                    name="audio"
                    type="file"
                    onChange={audioFileChange}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span">
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            <p className="text-secondary">{audioFile && audioFile.name}</p>
          </div>
        </div>

        <div className="text-end mt-4">
          <button onClick={handleSubmit} className="small-contained-button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
