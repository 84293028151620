import * as React from "react";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import { editCountry } from "src/DAL/Tax/Tax";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function EditTax({
  editValue,
  setshowPopEdit,
  getCountriesList,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = React.useState({
    country: "",
    countryCode: "",
    countryIso: "",
    tax: "",
    states: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    let postData = {
      country_name: inputs.country,
      dialing_code: inputs.countryCode,
      iso_code: inputs.countryIso,
      tax: inputs.tax.toString(),
      status: true,
      // states: inputs.states,
    };

    // setIsLoading(true);
    // console.log(editValue);
    const result = await editCountry(postData, editValue.id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setshowPopEdit(false);
      getCountriesList();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      // setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    if (editValue) {
      setInputs({
        ...inputs,
        ["country"]: editValue.country,
        ["countryIso"]: editValue.countryIso,
        ["countryCode"]: editValue.countryCode,
        ["tax"]: editValue.tax,
        ["states"]: editValue?.statesData,
      });
    }
  }, []);
  // if (isLoading === true) {
  //   return <CircularProgress className={classes.loading} color="primary" />;
  // }
  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Country Name"
              variant="outlined"
              fullWidth
              required
              name="country"
              value={inputs.country}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="Country Dialing Code"
                name="countryCode"
                variant="outlined"
                fullWidth
                required
                value={inputs.countryCode}
                onChange={handleChange}
              />
            </FormControl>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="Country ISO Code"
                name="countryIso"
                variant="outlined"
                fullWidth
                required
                value={inputs.countryIso}
                onChange={handleChange}
              />
            </FormControl>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="Tax %"
                name="tax"
                variant="outlined"
                fullWidth
                required
                type="number"
                onWheel={(event) => event.target.blur()}
                value={inputs.tax}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
