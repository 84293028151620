import * as React from "react";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import Autocomplete from "@mui/material/Autocomplete";

import { useNavigate, Link as RouterLink } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import { memberCompleteListing } from "src/DAL/member/member";
import { GroupListingApi } from "src/DAL/group/group";
import { SelfImageAdd } from "src/DAL/selfImage/selfImage";
import TinyEditor from "src/components/ckeditor/Ckeditor";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function AddJournalPrompts() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [personName, setPersonName] = useState([]);
  const [member, setMember] = useState([]);
  const [groupListing, setGroupListing] = useState([]);
  const [groupsName, setGroupsName] = useState([]);
  const [QuestionCk, setQuestionCk] = React.useState("");

  const [inputs, setInputs] = useState({
    question: "",
    questionType: "textbox",
    questionPlaceholder: "",
    status: "true",
    groups: [],
    members: [],
  });

  const getMember = async () => {
    setIsLoading(true);
    const result = await memberCompleteListing();
    if (result.code === 200) {
      setPersonName(result.member);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getGroups = async () => {
    setIsLoading(true);
    const result = await GroupListingApi();
    if (result.code === 200) {
      let total_groups = result.group;
      let active_groups = [];
      total_groups.map((group) => {
        if (group.status === true) {
          active_groups.push(group);
        }
      });
      setGroupListing(active_groups);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleChangeGroup = (event) => {
    const {
      target: { value },
    } = event;
    setGroupsName(typeof value === "string" ? value.split(",") : value);
  };

  const handleSubmit = async (e) => {
    let group_array = [];
    let group_object = {};
    groupsName.map((group) => {
      group_object = {
        group_slug: group,
      };
      group_array.push(group_object);
    });

    let selected_member_array = [];
    let selected_member_object = {};
    member.map((member) => {
      selected_member_object = {
        member_id: member._id,
      };
      selected_member_array.push(selected_member_object);
    });

    // member id getting end
    const postData = {
      question_statement: QuestionCk,
      // question_statement: inputs.question,
      question_type: inputs.questionType,
      question_placeholder: inputs.questionPlaceholder,
      status: inputs.status,
      group: group_array,
      member: selected_member_array,
      module_type: "journal_prompt",
    };

    e.preventDefault();
    if (QuestionCk == "") {
      enqueueSnackbar("Question is Required", { variant: "error" });
    } else {
      setIsLoading(true);
      const result = await SelfImageAdd(postData);
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        navigate(`/journal-prompts`);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  useEffect(() => {
    getMember();
    getGroups();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h2>Add Journal Prompt</h2>
        </div>
      </div>
      <div className="row">
        {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Question"
            variant="outlined"
            required
            fullWidth
            name="question"
            value={inputs.question}
            onChange={handleChange}
          />
        </div> */}
        <div className="col-12 mt-3">
          <h4>Question*</h4>
          <TinyEditor
            setDetailDescription={setQuestionCk}
            detailDescriptionCk={QuestionCk}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Question Placeholder"
            variant="outlined"
            fullWidth
            name="questionPlaceholder"
            value={inputs.questionPlaceholder}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Question Type *
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="questionType"
              value={inputs.questionType}
              label="Question Type *"
              onChange={handleChange}>
              <MenuItem value="textbox">Textbox</MenuItem>
              <MenuItem value="textarea">Textarea</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Question Status *
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="status"
              value={inputs.status}
              label="Question Status *"
              onChange={handleChange}>
              <MenuItem value="true">Active</MenuItem>
              <MenuItem value="false">Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="mt-4">
            <FormControl className="mt-3" fullWidth>
              <InputLabel id="demo-multiple-name-label">Groups</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={groupsName}
                onChange={handleChangeGroup}
                input={<OutlinedInput label="Groups" />}
                MenuProps={MenuProps}>
                {groupListing.map((name) => (
                  <MenuItem
                    key={name}
                    value={name.group_slug}
                    style={getStyles(name, groupsName, theme)}>
                    {name.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="mt-4">
            <Autocomplete
              multiple
              id="tags-outlined"
              options={personName}
              getOptionLabel={(option) =>
                option.first_name +
                " " +
                option.last_name +
                " (" +
                option.email +
                ")"
              }
              filterSelectedOptions
              value={member}
              onChange={(event, newValue) => {
                setMember(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Members" placeholder="Members" />
              )}
            />
          </div>
        </div>

        <div className="text-end mt-4">
          <button onClick={handleSubmit} className="small-contained-button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
