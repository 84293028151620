import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { activeMemberListing } from "src/DAL/member/member";
import { makeStyles } from "@mui/styles";
import Autocomplete from "@mui/material/Autocomplete";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DatePicker } from "@mui/lab";
import { Icon } from "@iconify/react";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { get_root_value } from "src/utils/domUtils";
import moment from "moment";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function FeedsFilter({
  getFeedsList,
  setMember,
  setDates,
  member,
  inputs,
  dates,
  setInputs,
  handleCloseFilterDrawer,
}) {
  const [personName, setPersonName] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();
  const handleSubmit = (e) => {
    e.preventDefault();
    let postData = {};
    if (dates.start_date) {
      postData.start_date = moment(dates.start_date).format("MMM-DD-YYYY");
    }
    if (dates.end_date) {
      postData.end_date = moment(dates.end_date).format("MMM-DD-YYYY");
    }
    if (
      inputs.status == true ||
      (inputs.status == false && inputs.status !== "")
    ) {
      postData.status = inputs.status;
    }

    if (member) {
      postData.search_text = member?.first_name + " " + member?.last_name;
    }
    console.log(postData, "postData");

    if (dates.start_date && dates.end_date) {
      handleCloseFilterDrawer();
      getFeedsList(postData);
    } else {
      if (!dates.start_date && !dates.end_date) {
        handleCloseFilterDrawer();
        getFeedsList(postData);
      } else {
        if (!dates.start_date) {
          enqueueSnackbar("Please Select Start Date", { variant: "error" });
        } else {
          enqueueSnackbar("Please Select End Date", { variant: "error" });
        }
      }
    }
  };
  const handleClear = (e) => {
    e.preventDefault();
    setMember(null);
    setDates((prevState) => {
      return {
        start_date: null,
        end_date: null,
      };
    });
    setInputs((prevState) => {
      return {
        status: "",
      };
    });
    let postData = {};
    getFeedsList(postData);
    handleCloseFilterDrawer();
  };
  const getMember = async () => {
    setIsLoading(true);
    const result = await activeMemberListing();
    if (result.code === 200) {
      setPersonName(result.member);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleChangeDate = (event, date) => {
    if (date == "start") {
      setDates((prevState) => {
        return {
          ...prevState,
          start_date: event,
        };
      });
    } else {
      setDates((prevState) => {
        return {
          ...prevState,
          end_date: event,
        };
      });
    }
  };
  useEffect(() => {
    getMember();
  }, []);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container px-2">
      <div className="col-12 mt-4">
        <Autocomplete
          // multiple
          id="tags-outlined"
          options={personName}
          getOptionLabel={(option) =>
            option?.first_name +
            " " +
            option?.last_name +
            " (" +
            option?.email +
            ")"
          }
          // filterSelectedOptions
          value={member}
          onChange={(event, newValue) => {
            setMember(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Members" placeholder="Members" />
          )}
        />
      </div>
      <div className="col-12 mt-3">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="status"
            value={inputs.status}
            label="Status"
            onChange={handleChange}>
            <MenuItem value={true}>Active</MenuItem>
            <MenuItem value={false}>InActive</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="filter">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Start Date"
            name="start_date"
            value={dates.start_date}
            format="YYYY-MM-DD"
            inputFormat="dd/MM/yyyy"
            onChange={(e) => handleChangeDate(e, "start")}
            renderInput={(params) => (
              <TextField
                {...params}
                className="mt-3 inputs-fields"
                //   required={true}
                // style={{ width: "100%" }}
              />
            )}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="End Date"
            name="end_date"
            value={dates.end_date}
            format="YYYY-MM-DD"
            inputFormat="dd/MM/yyyy"
            onChange={(e) => handleChangeDate(e, "end")}
            renderInput={(params) => (
              <TextField
                {...params}
                className="mt-3 ms-3 inputs-fields"
                //   required={true}
                // style={{ width: "100%" }}
              />
            )}
          />
        </LocalizationProvider>
      </div>
      <Box sx={{ pl: 4, pr: 4, pt: 4 }}>
        <Button
          fullWidth
          size="large"
          type="submit"
          color="inherit"
          variant="outlined"
          onClick={handleSubmit}
          startIcon={
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: 5 }}>
              <path
                d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                fill={get_root_value("--portal-theme-primary")}
              />
            </svg>
          }>
          Filter
        </Button>
      </Box>
      <Box sx={{ pl: 4, pr: 4, pt: 3, pb: 1 }}>
        <Button
          fullWidth
          size="large"
          type="submit"
          color="inherit"
          variant="outlined"
          onClick={handleClear}
          startIcon={<Icon icon={roundClearAll} />}>
          Clear All
        </Button>
      </Box>
    </div>
  );
}
