import * as React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { Icon } from "@iconify/react";

import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { IconButton, Divider, CircularProgress } from "@mui/material";
import {
  add_programme_bulk_access_api,
  programmeListings,
} from "src/DAL/Programme/Programme";
import ProgrammeBulkMembers from "./ProgrammeBulkMembers";

// import { get_root_value } from "src/utils/domUtils";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Input = styled("input")({
  display: "none",
});

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function ProgrammeBulkAccess() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { state } = useLocation();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [detailDescriptionCk, setDetailDescriptionCk] = React.useState("");
  const [date, setDate] = useState(new Date());
  const [selected, setSelected] = useState([]);
  const [reload, setReload] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState([]);
  const [programValue, setProgramValue] = React.useState([]);
  const [csvData, setCsvData] = useState([]);
  const [inputs, setInputs] = React.useState({
    change_for: "specific",
    detailed_description: "",
    program_access_type: "",
  });
  const headers = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Date of  Birth", key: "birthDate" },
    { label: "Contact No.", key: "contact_number" },
    { label: "Country", key: "country" },
    { label: "City", key: "city" },
    { label: "State", key: "state" },
    { label: "Street Address", key: "street" },
    { label: "Zip Code", key: "zip_code" },
    { label: "Time Zone", key: "time_zone" },
    { label: "Program", key: "program" },
  ];
  function download_csv_file(csv, csvFileData) {
    const headerRow = csv.map((column) => column.label);
    const csvRows = [headerRow];

    csvFileData.forEach((row) => {
      const dataRow = csv.map((column) => {
        const value = row[column.key] || "";
        if (value.includes(",")) {
          return `"${value}"`;
        }
        return value.toString();
      });

      csvRows.push(dataRow);
    });

    const csvContent = csvRows.map((row) => row.join(",")).join("\n");

    const hiddenElement = document.createElement("a");
    hiddenElement.href =
      "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);
    hiddenElement.target = "_blank";
    hiddenElement.download = "Members.csv";
    hiddenElement.click();
  }
  const handleExportClick = () => {
    download_csv_file(headers, csvData);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    let postData = {
      type: inputs.change_for,
      program: params.id,
      program_access_type: params.type,
    };
    if (inputs.change_for == "specific") {
      postData.member = selected;
    }

    setIsLoading(false);
    if (selected.length != 0) {
      setIsLoading(true);
      const result = await add_programme_bulk_access_api(postData);
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        //   navigate(-1);
        setReload(!reload);
        setIsLoading(false);
        setSelected([]);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const programmeListData = async () => {
    let result = await programmeListing();
    if (result.code == 200) {
      setProgramValue(result.program);
    }
  };

  React.useEffect(() => {
    programmeListData();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-8">
          <h2>
            {params.type == "add"
              ? "Add Member Program Access"
              : "Manage Member Program Access"}
          </h2>
        </div>
        <div className="col-4 text-end">
          {csvData.length > 0 && (
            <button
              className="small-contained-button me-2 mt-1 mb-4"
              onClick={handleExportClick}>
              Export Csv &nbsp;&nbsp; <Icon icon={"ic:outline-file-download"} />
            </button>
          )}
        </div>
      </div>
      <form onSubmit={handleSubmit} className="mb-5">
        <div className="row">
          {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Choose Access Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="program_access_type"
                value={inputs.program_access_type}
                label="Change For All Users"
                onChange={handleChange}
              >
                <MenuItem value="add">Add Access</MenuItem>
                <MenuItem value="remove">Remove Access</MenuItem>
              </Select>
            </FormControl>
          </div> */}
          {/* {
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">
                  Programme Access For
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="change_for"
                  value={inputs.change_for}
                  label="Programme Access For"
                  onChange={handleChange}>
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="specific">Specific</MenuItem>
                </Select>
              </FormControl>
            </div>
          } */}
          {selected.length === 0 && params.type == "remove" ? (
            ""
          ) : (
            <div
              className="text-end mt-4"
              id={`${inputs.change_for == "specific" ? "fixedbutton" : ""}`}>
              <button
                className="small-contained-button"
                disabled={selected.length === 0}>
                {params.type == "remove" ? "Remove Access" : "Submit"}
              </button>
            </div>
          )}
        </div>
      </form>
      {/* {inputs.change_for == "specific" && (
        <ProgrammeBulkMembers
          selected={selected}
          setSelected={setSelected}
          reload={reload}
        />
      )} */}
      <ProgrammeBulkMembers
        selected={selected}
        setSelected={setSelected}
        reload={reload}
        setCsvData={setCsvData}
        csvData={csvData}
        state={state}
      />
    </div>
  );
}
