import { invokeApi } from "../../bl_libs/invokeApi";

export const getCountryList = async () => {
  const requestObj = {
    path: `/api/country/`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const addCountry = async (data) => {
  const requestObj = {
    path: `/api/country/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addState = async (data) => {
  const requestObj = {
    path: `/api/country/add_state`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editCountry = async (data, id) => {
  const requestObj = {
    path: `/api/country/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editState = async (data) => {
  const requestObj = {
    path: `/api/country/update_state`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteCountry = async (id) => {
  const requestObj = {
    path: `/api/country/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const deleteState = async (data) => {
  const requestObj = {
    path: `/api/country/delete_state`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const detailCountry = async (id) => {
  const requestObj = {
    path: `/api/country/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
