import { invokeApi } from "../../bl_libs/invokeApi";

export const dynamite_events_list_api = async (search) => {
  const requestObj = {
    path: `/api/dynamite_event?search_text=${search ? search : ""}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const events_programs_product_lead_status_api = async (search) => {
  const requestObj = {
    path: `/api/member/get_data_list/plan`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const dynamite_events_delegate_list_api = async (
  id,
  page,
  limit,
  search
) => {
  const requestObj = {
    path: `/api/consultant/consultant_list/event/${id}?page=${page}&limit=${limit}&search_text=${
      search ? search : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_delegate_event_access_api = async (data) => {
  const requestObj = {
    path: `/api/dynamite_event/add_or_remove_consultant/event`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const add_dynamite_event_api = async (data) => {
  const requestObj = {
    path: `/api/dynamite_event/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_dynamite_lock_content_event_api = async (data, id) => {
  const requestObj = {
    path: `/api/dynamite_event/update/lock_configration/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_event_config_api = async (data, id) => {
  const requestObj = {
    path: `/api/dynamite_event/update/event_timer_configration/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const add_dynamite_event_start_expiry_api = async (data) => {
  const requestObj = {
    path: `/api/dynamite_event/edit_dynamite_event_start_or_expiry_date`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_dynamite_event_api = async (data, slug) => {
  const requestObj = {
    path: `/api/dynamite_event/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const dynamite_event_detail_api = async (slug) => {
  const requestObj = {
    path: `/api/dynamite_event/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const delete_dynamite_event_api = async (id) => {
  const requestObj = {
    path: `/api/dynamite_event/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const duplicate_dynamite_event_api = async (id) => {
  const requestObj = {
    path: `/api/dynamite_event/add/dulicate/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const update_question_configration = async (data, slug) => {
  const requestObj = {
    path: `/api/dynamite_event/question_configration/update/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const dynamite_events_members_api = async (
  event_id,
  page,
  limit,
  search
) => {
  const requestObj = {
    path: `/api/dynamite_event/get_dynamite_event_member_by_pagination/${event_id}?search_text=${search}&page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};
export const add_member_from_proggram_event_Api = async (data) => {
  const requestObj = {
    path: `/api/dynamite_event/assign_program_members_to_events`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const delete_dynamite_event_member = async (data) => {
  const requestObj = {
    path: `/api/dynamite_event/delete_event_member/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const get_live_dynamite_event_another_member = async (
  event_id,
  text
) => {
  const requestObj = {
    path: `/api/dynamite_event/get_dynamite_event_member_by_event/${event_id}?search_text=${text}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const add_dynamite_event_member = async (data) => {
  const requestObj = {
    path: `/api/dynamite_event/add_member_in_event/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const edit_dynamite_event_member = async (data) => {
  const requestObj = {
    path: `/api/dynamite_event/edit_event_member/`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_member_from_csv_for_event = async (data) => {
  const requestObj = {
    path: `/api/dynamite_event/add_member_from_csv_for_event`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
