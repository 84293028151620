import { useFormik } from "formik";
import { useEffect, useState } from "react";
// material
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
//
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { admin_videos_listing } from "src/DAL/HelpingVideos/AdminHelpingVideos";
import RecordNotFound from "src/components/RecordNotFound";
import SearchIcon from "@mui/icons-material/Search";

// ----------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ClientVideos() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [adminVideos, setAdminVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState("");

  const programsList = async () => {
    const result = await admin_videos_listing("client");
    if (result.code == 200) {
      setIsLoading(false);
      setAdminVideos(result.helpingvideo);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleNavigateDetail = (value) => {
    navigate(
      `/client-videos/client-videos-detail/${value.helping_video_slug}`,
      {
        state: { detailValues: value },
      }
    );
  };
  const handleChangeSearch = (e) => {
    setQuery(e.target.value);
  };
  const getFilteredData = (query) => {
    let dataToFilter = [...adminVideos];
    if (query) {
      let resultFilter = dataToFilter.filter((post) =>
        post.title.toLowerCase().includes(query.toLowerCase())
      );
      return resultFilter;
    } else {
      return dataToFilter;
    }
  };
  useEffect(() => {
    programsList();
  }, []);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-5 col-sm-12">
          <h2>Client Helping Videos </h2>
        </div>
        {/* <div className="col-lg-5 col-sm-12 text-end">
          <button
            onClick={() => navigate(`/client-videos/add-client-videos`)}
            className="small-contained-button">
            Add Client Videos
          </button>
        </div> */}
        <div className="col-lg-7 col-sm-12 text-end">
          <FormControl variant="outlined" sx={{ marginTop: 1 }}>
            <InputLabel htmlFor="outlined-adornment-password">
              Search
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              className="search-bar"
              type="text"
              variant="small"
              onChange={handleChangeSearch}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="Search"
            />
          </FormControl>
          <button
            onClick={() => navigate(`/client-videos/add-client-videos`)}
            className="small-contained-button mt-4">
            Add Client Videos
          </button>
        </div>
        {adminVideos.length < 1 ? (
          <RecordNotFound title="Client Videos" />
        ) : (
          <>
            {getFilteredData(query)?.map((value, index) => {
              return (
                <div className="col-lg-4 col-md-4 col-sm-12 mb-4" key={index}>
                  <div className="card mt-4 pods-cards-shadow cursor h-100">
                    <img
                      src={s3baseUrl + value.thumbnail_image.thumbnail_1}
                      className="card-img-top pods-image"
                      alt="Video"
                      onClick={() => handleNavigateDetail(value)}
                    />
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12">
                          <h3
                            className="h2-heading"
                            onClick={() => handleNavigateDetail(value)}>
                            {htmlDecode(value.title)}
                          </h3>
                        </div>

                        <div className="col-2 menu-option"></div>
                      </div>
                      <p className="programme-card-desc">
                        {htmlDecode(value.short_description)}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}
