import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// material
import { alpha, useTheme, styled } from "@mui/material/styles";
import InboxIcon from "@mui/icons-material/MoveToInbox";
// drop
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import DateRangeIcon from "@mui/icons-material/DateRange";
import SettingsIcon from "@mui/icons-material/Settings";
import { project_name } from "src/config/config";
// drop
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
import { get_root_value } from "src/utils/domUtils";
import { fontSize } from "@mui/system";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import { defaultSettingApi } from "src/DAL/SiteSetting/siteSetting";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import NavSectionItem from "./NavSectionItem";

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  "&:before": {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: "none",
    position: "absolute",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active }) {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: theme.palette.primary.main,
    fontWeight: "fontWeightMedium",
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    "&:before": { display: "block" },
  };

  const activeSubStyle = {
    color: get_root_value("--portal-theme-primary"),
    fontWeight: "fontWeightMedium",
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}>
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Box
            component={Icon}
            icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}>
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: "flex",
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "text.disabled",
                        transition: (theme) =>
                          theme.transitions.create("transform"),
                        ...(isActiveSub && {
                          transform: "scale(2)",
                          bgcolor: "primary.main",
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}>
      <ListItemIconStyle
        sx={{
          ...(isActiveRoot && activeRootStyle),
        }}>
        {icon && icon}
      </ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { setFavIconImage, setMetaTitle, setMetaDescription } = usePGIMode();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openSession, setOpenSession] = useState(false);
  const [openExtras, setOpenExtras] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [openSupport, setOpenSupport] = useState(false);
  const [openClient, setOpenClient] = useState(false);
  const [openConsultant, setOpenConsultant] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);
  const [openWebsiteSetting, setOpenWebsiteSetting] = useState(false);
  const [openCommunity, setOpenCommunity] = useState(false);
  const [openHelpingVideos, setOpenHelpingVideos] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const getNavItemsList = (array, query) => {
    if (query) {
      const _nav_list = array
        .filter(
          (data) =>
            data.title.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
            data.child_options?.some(
              (item) =>
                item.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
            )
        )
        .map((data) => {
          return {
            ...data,
            child_options: data.child_options?.filter(
              (item) =>
                item.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
            ),
          };
        });
      return _nav_list;
    }
    return array;
  };
  const handleClick = () => {
    setOpenDropdown(!openDropdown);
  };
  const handleClickSession = () => {
    setOpenSession(!openSession);
  };
  const handleClickExtras = () => {
    setOpenExtras(!openExtras);
  };
  const handleClickPayment = () => {
    setOpenPayment(!openPayment);
  };
  const handleClickCalendar = () => {
    setOpenCalendar(!openCalendar);
  };
  const handleClickSupport = () => {
    setOpenSupport(!openSupport);
  };
  const handleClickHelpingVideos = () => {
    setOpenHelpingVideos(!openHelpingVideos);
  };
  const handleNavigateAdminVideos = () => {
    navigate("/admin-videos");
  };
  const handleNavigateClientVideos = () => {
    navigate("/client-videos");
  };
  const handleClickClient = () => {
    setOpenClient(!openClient);
  };
  const handleClickConsultant = () => {
    setOpenConsultant(!openConsultant);
  };
  const handleClickSetting = () => {
    setOpenSetting(!openSetting);
  };
  const handleClickWebsiteSetting = () => {
    setOpenWebsiteSetting(!openWebsiteSetting);
  };

  const handleNavigateRecordings = () => {
    navigate("/recording-list");
  };
  const handleNavigateCategory = () => {
    navigate("/categories");
  };
  const handleNavigateSessions = () => {
    navigate("/sessions");
  };
  const handleNavigateSessionsRecording = () => {
    navigate("/session-recording");
  };
  const handleNavigateQuotes = () => {
    navigate("/quotesList");
  };
  const handleNavigateGratitude = () => {
    navigate("/gratitude");
  };
  const handleNavigateAffirmation = () => {
    navigate("/affirmation");
  };
  const handleNavigateAffirmationCategories = () => {
    navigate("/affirmations-categories");
  };
  const handleNavigateTransaction = () => {
    navigate("/payment-transactions");
  };
  const handleNavigateStripeProducts = () => {
    navigate("/stripe-products");
  };
  const handleNavigatePaymentTemplate = () => {
    navigate("/payment-template");
  };
  const handleNavigatePaymentRequest = () => {
    navigate("/payment-request");
  };
  const handleNavigateCalendarGroups = () => {
    navigate("/calendar-groups");
  };
  const handleNavigateCalendarPods = () => {
    navigate("/community-rooms");
  };
  const handleNavigateCalendar = () => {
    navigate("/calendarEvents");
  };
  const handleNavigateDepartment = () => {
    navigate("/departments");
  };
  const handleNavigateSupportTicket = () => {
    navigate("/support-ticket");
  };
  const handleNavigateGeneralSetting = () => {
    navigate("/general-setting");
  };
  const handleNavigateClientDashboard = () => {
    navigate("/client-dashboard");
  };
  const handleNavigateContentSetting = () => {
    navigate("/content-setting");
  };
  const handleNavigatePortals = () => {
    navigate("/danielle-events");
  };
  const handleNavigateEvents = () => {
    navigate("/website-events");
  };
  const handleNavigateDynamiteEvents = () => {
    navigate("/dynamite-events");
  };
  const handleNavigateDynamiteEventsCategory = () => {
    navigate("/dynamite-event-categories");
  };
  const handleNavigateDynamiteEventCategoryVideo = () => {
    navigate("/dynamite-event-category-videos");
  };
  const handleNavigateConsultantGeneralSetting = () => {
    navigate("/consultants-general-setting");
  };
  const handleNavigateSendGrid = () => {
    navigate("/send-grid");
  };
  const handleNavigateCompaign = () => {
    navigate("/add-compaign");
  };
  const handleNavigateStripe = () => {
    navigate("/add-stripe");
  };
  const handleNavigateEmailSetting = () => {
    navigate("/email-setting");
  };
  const handleNavigateInvoiceSetting = () => {
    navigate("/invoice-setting");
  };
  const handleNavigateDefaultSetting = () => {
    navigate("/default-setting");
  };
  const handleNavigateEmailTemplates = () => {
    navigate("/email-templates");
  };
  const handleNavigateWebsitePages = () => {
    navigate("/website-pages");
  };
  const handleNavigateWebsiteEvents = () => {
    navigate("/website-events");
  };
  const handleNavigateWebsiteResources = () => {
    navigate("/website-resources");
  };
  const handleNavigateWebsiteTestimonial = () => {
    navigate("/website-testimonials");
  };
  const handleNavigateWebsitePodCast = () => {
    navigate("/website-podcasts");
  };
  const handleNavigateWebsiteProgrammes = () => {
    navigate("/website-programs");
  };
  const handleNavigateGeneralPaymentPlan = () => {
    navigate("/website-general-payment-plans");
  };

  const handleNavigateProgrammeCategories = () => {
    navigate("/website-categories");
  };
  const handleNavigateFAQ = () => {
    navigate("/website-faq");
  };
  const handleNavigateTeam = () => {
    navigate("/website-team");
  };
  const handleNavigateMedia = () => {
    navigate("/media-section");
  };
  const handleNavigateSuccessProduct = () => {
    navigate("/success-product");
  };
  const handleNavigatePodcasts = () => {
    navigate("/website-podcasts");
  };

  const matchSideBar = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  const match = ({ path, matches }) => {
    if (matches) {
      let is_active = false;
      matches.forEach((match_path) => {
        const match = match_path
          ? !!matchPath({ path: match_path, end: false }, pathname)
          : false;

        if (match) {
          is_active = true;
        }
      });

      return is_active;
    }
    return path ? !!matchPath({ path, end: false }, pathname) : false;
  };
  const getSetting = async () => {
    const result = await defaultSettingApi();
    if (result.code == 200) {
      localStorage.setItem("favIcon", result.default_setting.admin_favicon);
      localStorage.setItem("adminLogo", result.default_setting.admin_logo);
      localStorage.setItem("metaTitle", result.default_setting.meta_title);
      localStorage.setItem(
        "metaDescription",
        result.default_setting.meta_description
      );
      setFavIconImage(result.default_setting.admin_favicon);
      setMetaTitle(result.default_setting.meta_title);
      setMetaDescription(result.default_setting.meta_description);
    }
  };

  useEffect(() => {
    getSetting();
  }, []);
  return (
    <Box {...other}>
      <div className="my-1 p-2">
        <div className="sidebar-search-box">
          <Icon
            fontSize={20}
            className="sidebar-search-icon"
            icon="akar-icons:search"
          />
          <input
            className="sidebar-search-input"
            type="text"
            placeholder="Search"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </div>
      </div>
      <List disablePadding>
        {getNavItemsList(navConfig, searchInput).map((item, i) => {
          return (
            <NavSectionItem key={i} data={item} searchInput={searchInput} />
          );
        })}
      </List>
    </Box>
  );
}
