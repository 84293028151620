import React from "react";
import { filter } from "lodash";
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  CircularProgress,
} from "@mui/material";
// components
import SearchNotFound from "../../components/SearchNotFound";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import CustomPopoverSectionEvents from "src/components/MenuOption/CustomPopoverSectionEvents";
import { UserListHead } from "../../components/_dashboard/user";
//
import USERLIST from "../../_mocks_/user";
import { s3baseUrl } from "../../config/config";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import UserListToolbars from "src/components/_dashboard/user/UserListToolbars";
import Label from "src/components/Label";
import MemberImportFromProgram from "./MemberImportFromProgram";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import {
  delete_dynamite_event_member,
  dynamite_events_members_api,
} from "src/DAL/DanielleEvents/DanielleEvents";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  { id: "name", label: " Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "start_date", label: "Event Start Date", alignRight: false },
  { id: "expiry_date", label: "Event Expiry Date", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "action", label: "Action", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (row) => row.fulName.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function Members() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("number");
  const [page, setPage] = useState(0);
  const [filterName, setFilterName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(500);
  const [openDelete, setOpenDelete] = useState(false);
  const [openMultiDelete, setOpenMultiDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [csvFile, setCsvFile] = useState("");
  const [filterNameStatus, setFilterNameStatus] = useState(false);
  const [eventsName, setEventsName] = useState(null);
  const [programsSelected, setProgramsSelected] = React.useState([]);
  const [importMemberDrawerState, setImportMemberDrawerState] = useState(false);

  const headers = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Event Start Date", key: "event_start_date" },
    { label: "Event Expiry Date", key: "event_expiry_date" },
    // { label: "Country", key: "country" },
    // { label: "City", key: "city" },
    // { label: "State", key: "state" },
    // { label: "Street Address", key: "street" },
    // { label: "Zip Code", key: "zip_code" },
    // { label: "Time Zone", key: "time_zone" },
    // { label: "Programs", key: "memberProgram" },
  ];
  function download_csv_file(csv, csvFileData) {
    const headerRow = csv.map((column) => column.label);
    const csvRows = [headerRow];

    csvFileData.forEach((row) => {
      const dataRow = csv.map((column) => {
        let value = row[column.key] || "";
        if (value.includes(",")) {
          return `"${value}"`;
        }
        return value.toString();
      });

      csvRows.push(dataRow);
    });

    const csvContent = csvRows.map((row) => row.join(",")).join("\n");

    const hiddenElement = document.createElement("a");
    hiddenElement.href =
      "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);
    hiddenElement.target = "_blank";
    hiddenElement.download = "EventMembers.csv";
    hiddenElement.click();
  }
  const handleExportClick = () => {
    download_csv_file(headers, csvData);
  };
  const getMemberListing = async () => {
    setIsLoading(true);

    const result = await dynamite_events_members_api(
      params.id,
      page,
      rowsPerPage,
      filterName
    );

    if (result.code == 200) {
      setCsvFile(result?.import_event_user_sample_file);
      const members = result?.member?.map((member, index) => {
        return {
          ...member,
          id: member._id,
          fulName: member.first_name + " " + member.last_name,
          name: member.first_name,
          lastName: member.last_name,
          status: member.status,
          email: member.email,
          profile: member.profile_image,
          street: member.street,
          time_zone: member.time_zone,
          expiry_date: member.event_expiry_date,
          start_date: member.event_start_date,
        };
      });
      const csvExportData = result.member?.map((member) => {
        // let programList = member?.program.map((program) => {
        //   return program?._id?.title;
        // });
        // let dateofbirth = "";
        // if (member.dob) {
        //   let startDate = new Date(member?.dob);
        //   const year = startDate.getFullYear();
        //   const month = String(startDate.getMonth() + 1).padStart(2, "0");
        //   const day = String(startDate.getDate()).padStart(2, "0");
        //   dateofbirth = `${year}-${month}-${day}`;
        // }
        return {
          // ...member,
          name: member?.first_name + " " + member?.last_name,
          email: member?.email,
          event_start_date: member?.event_start_date,
          event_expiry_date: member?.event_expiry_date,
          // memberProgram: programList.join(", "),
          // birthDate: dateofbirth,
        };
      });
      setCsvData(csvExportData);
      setData(members);
      setTotalCount(result.total_member_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setPage(0);
  };
  const handleSearchName = () => {
    getMemberListing();
    setFilterNameStatus(true);
  };
  const emptyRows = page > 0 && 0;

  const filteredUsers = applySortFilter(data, getComparator(order, orderBy));

  const isUserNotFound = filteredUsers.length === 0;

  const handleEdit = (value) => {
    navigate(`/danielle-events/${params.id}/edit-member/${value._id}`, {
      state: value,
    });
  };
  const handleOpenImportDrawer = (value) => {
    setEventsName(value.id);
    setImportMemberDrawerState(true);
  };
  const handleCloseImportDrawer = () => {
    setImportMemberDrawerState(false);
  };

  const handleRegisterEvents = () => {
    navigate(`/danielle-events/register-events/${params.id}`);
  };
  const handleAddMembers = () => {
    navigate(`/danielle-events/${params.id}/add-members`);
  };

  const handleChangeBilling = (value) => {
    navigate(`/member/view-billing-list/${value.id}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleSampleCsv = (value) => {
    window.location.href = s3baseUrl + csvFile;
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    const formData = new FormData();
    formData.append("member", deleteDoc._id);
    formData.append("dynamite_event", params.id);
    const result = await delete_dynamite_event_member(formData);
    if (result.code === 200) {
      getMemberListing();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const MENU_OPTIONS_NEW = [
    {
      label: "Add Members",
      icon: "akar-icons:edit",
      handleClick: handleAddMembers,
    },
    {
      label: "Import Members From Program",
      icon: "ci:list-add",
      handleClick: handleOpenImportDrawer,
    },
    {
      label: "Import Members From Csv",
      icon: "mingcute:file-import-line",
      handleClick: handleRegisterEvents,
    },
    {
      label: "Sample Csv File",
      icon: "iconamoon:file",
      handleClick: handleSampleCsv,
    },
    {
      label: "Export Csv",
      icon: "ic:round-download",
      handleClick: handleExportClick,
    },
  ];

  useEffect(() => {
    getMemberListing();
  }, [rowsPerPage, page]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      {/* <CustomConfirmation
        open={openMultiDelete}
        setOpen={setOpenMultiDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleMultipleDelete}
      /> */}
      <div className="container">
        <div className="row mobile-margin display-flex">
          <div className="col-12">
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
          </div>
        </div>
        <div className="row mb-3 mt-2">
          <div className="col-lg-11 col-sm-12">
            <h2>Members</h2>
          </div>
          <div className="col-lg-1 col-sm-12 text-end">
            <CustomPopoverSectionEvents menu={MENU_OPTIONS_NEW} data={params} />
          </div>
        </div>

        <Card>
          <UserListToolbars
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            onSearch={handleSearchName}
          />

          <TableContainer sx={{ minWidth: 350 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers.map((row, i) => {
                  const {
                    id,
                    fulName,
                    status,
                    email,
                    profile,
                    expiry_date,
                    start_date,
                  } = row;
                  return (
                    <TableRow hover key={id}>
                      <TableCell align="left">
                        {page * rowsPerPage + (i + 1)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Avatar alt={name} src={s3baseUrl + profile} />
                          <Typography variant="subtitle2" noWrap>
                            {fulName}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>{email}</TableCell>
                      <TableCell>{start_date}</TableCell>

                      <TableCell>{expiry_date}</TableCell>

                      <TableCell align="left">
                        <Label
                          variant="ghost"
                          color={status === false ? "error" : "success"}>
                          {status === false ? "InActive" : "Active"}
                        </Label>
                      </TableCell>

                      <TableCell>
                        <CustomPopoverSection menu={MENU_OPTIONS} data={row} />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={11} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={14} sx={{ py: 3 }}>
                      <SearchNotFound />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 50, 100, 200, 500]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </div>
      <CustomDrawer
        isOpenDrawer={importMemberDrawerState}
        onOpenDrawer={handleOpenImportDrawer}
        onCloseDrawer={handleCloseImportDrawer}
        pageTitle="Import Members"
        componentToPassDown={
          <MemberImportFromProgram
            setSelectedProgram={setProgramsSelected}
            selectedProgram={programsSelected}
            onCloseDrawer={handleCloseImportDrawer}
            dataList={getMemberListing}
            eventsName={eventsName}
            setEventsName={setEventsName}
          />
        }
      />
    </>
  );
}
