import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import {
  AddInvoiceSettingApi,
  invoiceSettingApi,
} from "src/DAL/ClientSetting/ClientSetting";
import { s3baseUrl } from "src/config/config";
import TinyEditor from "src/components/ckeditor/Ckeditor";
import { uploadImageOns3 } from "src/DAL/commonApi/commonApi";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const Input = styled("input")({
  display: "none",
});

export default function InvoiceSetting() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = useState();
  const [companyDescriptionCks, setCompanyDescriptionCks] = React.useState("");
  const [footerDescriptionCks, setFooterDescriptionCks] = React.useState("");
  const [oldLogo, setOldLogo] = useState("");

  const [inputs, setInputs] = React.useState({
    image: {},
    logo: {},
    company_info_text: "",
    billing_info_text: "",
    description_text: "",
  });
  const siteSettingData = async () => {
    setIsLoading(true);
    const result = await invoiceSettingApi();
    if (result.code == 200) {
      setCompanyDescriptionCks(
        result?.content_setting?.invoice_setting?.company_info_detail
      );
      setFooterDescriptionCks(
        result?.content_setting?.invoice_setting?.footer_detail
      );
      setInputs({
        ...inputs,
        ["company_info_text"]:
          result?.content_setting?.invoice_setting?.company_info_text,
        ["billing_info_text"]:
          result?.content_setting?.invoice_setting?.billing_info_text,
        ["description_text"]:
          result?.content_setting?.invoice_setting?.description_text,
      });
      setOldLogo(result?.content_setting?.invoice_setting?.invoice_brand_logo);

      setIsLoading(false);
    }
  };
  const fileChangedHandler = async (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    formData.append("width", "600");

    const imageUpload = await uploadImageOns3(formData);
    if (imageUpload.code == 200) {
      setInputs({
        ...inputs,
        ["logo"]: imageUpload.image_path,
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let postData = {
      company_info_text: inputs.company_info_text,
      billing_info_text: inputs.billing_info_text,
      description_text: inputs.description_text,
      company_info_detail: companyDescriptionCks,
      footer_detail: footerDescriptionCks,
    };
    if (file) {
      postData.invoice_brand_logo = inputs.logo;
    } else {
      postData.invoice_brand_logo = oldLogo;
    }
    let formData = {
      invoice_setting: postData,
    };

    setIsLoading(true);
    console.log(formData, "formdata");

    const result = await AddInvoiceSettingApi(formData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  React.useEffect(() => {
    siteSettingData();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>Invoice Setting</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Company Info Text"
            variant="outlined"
            fullWidth
            required
            name="company_info_text"
            value={inputs.company_info_text}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Billing Info Text"
            variant="outlined"
            fullWidth
            required
            name="billing_info_text"
            value={inputs.billing_info_text}
            onChange={handleChange}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Description Text"
            variant="outlined"
            fullWidth
            name="description_text"
            value={inputs.description_text}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Invoice Logo</p>
              <FormHelperText className="pt-0">
                (Recommended Size 250 X 100)
              </FormHelperText>
            </div>
            <div className="col-2">
              {file ? (
                <img className="image-border" src={file} height="50" />
              ) : (
                oldLogo && (
                  <img
                    className="image-border"
                    src={s3baseUrl + oldLogo}
                    height="50"
                  />
                )
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  name="image"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span">
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs?.logo?.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs?.logo?.name}</p>
          )}
        </div>
        <div className="col-12 mt-5">
          <h4>Company Info Detail</h4>
          <TinyEditor
            setDetailDescription={setCompanyDescriptionCks}
            detailDescriptionCk={companyDescriptionCks}
          />
        </div>
        <div className="col-12 mt-5">
          <h4>Invoice Footer Section</h4>
          <TinyEditor
            setDetailDescription={setFooterDescriptionCks}
            detailDescriptionCk={footerDescriptionCks}
          />
        </div>

        <div className="text-end mt-4">
          <button onClick={handleSubmit} className="small-contained-button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
