import * as React from "react";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Stack from "@mui/material/Stack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import TinyEditor from "../../components/ckeditor/Ckeditor";
import { s3baseUrl } from "src/config/config";
import {
  dynamite_event_detail_api,
  update_dynamite_event_api,
} from "src/DAL/DanielleEvents/DanielleEvents";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});
export default function EditEvent() {
  const navigate = useNavigate();
  const { event_slug } = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = React.useState();
  const [date, setDate] = React.useState(new Date());
  const [detailDescriptionCk, setDetailDescriptionCk] = React.useState("");
  const { state } = useLocation();
  const [inputs, setInputs] = React.useState({
    title: "",
    status: true,
    chat: true,
    showStatus: true,
    image: {},
    accessType: "unlimited",
    videoUrl: "",
    short_description: "",
    detailed_description: "",
    days: "",
    order: "",
  });
  const [oldImages, setOldImages] = useState({
    image: "",
  });
  const [images, setImages] = useState({
    image: "",
  });
  const fileChangedHandler = (e) => {
    const { name, files } = e.target;

    setImages({
      [name]: URL.createObjectURL(files[0]),
    });

    setInputs({
      ...inputs,
      [name]: files[0],
    });
  };

  const handleChangeDate = (newValue) => {
    setDate(newValue);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.days < 0) {
      enqueueSnackbar("No of days must be positive number ", {
        variant: "error",
      });
    } else {
      const startDate = new Date(date);
      const year = startDate.getFullYear();
      const month = String(startDate.getMonth() + 1).padStart(2, "0");
      const day = String(startDate.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      const formData = new FormData();
      formData.append("title", inputs.title);
      formData.append("short_description", inputs.short_description);
      formData.append("detail_description", detailDescriptionCk);
      formData.append("status", inputs.status);
      formData.append("order", inputs.order);
      formData.append("video_url", inputs.videoUrl);
      formData.append("is_chat_enabled", inputs.chat);
      formData.append("is_show_on_list", inputs.showStatus);
      formData.append("no_of_days", inputs.days);
      formData.append("start_date", formattedDate);
      if (images.image) {
        formData.append("image", inputs.image);
      }
      // if (file) {
      //   formData.append("main_image", inputs.image);
      // }
      setIsLoading(true);
      const result = await update_dynamite_event_api(formData, event_slug);
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        navigate(`/danielle-events`);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const getEventDetail = async () => {
    setIsLoading(true);
    const result = await dynamite_event_detail_api(event_slug);
    if (result.code === 200) {
      handleFormat(result.dynamite_event);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleFormat = (data) => {
    console.log(data, "dataaaa");
    setInputs({
      ...inputs,
      ["title"]: data.title,
      ["order"]: data.order,
      ["status"]: data.status,
      ["showStatus"]: data.is_show_on_list,
      ["videoUrl"]: data.video_url,
      ["detailed_description"]: data.detail_description,
      ["short_description"]: data.short_description,
      ["days"]: data.no_of_days,
      ["chat"]: data.is_chat_enabled,
    });
    setDetailDescriptionCk(data.detail_description);
    setDate(data.start_date);
    setOldImages({
      ["image"]: data?.images?.thumbnail_2,
    });
    setIsLoading(false);
  };
  // React.useEffect(() => {
  //   if (state) {
  //     setInputs({
  //       ...inputs,
  //       ["title"]: state.name,
  //       ["order"]: state.order,
  //       ["status"]: state.status,
  //     });
  //   }
  // }, []);
  useEffect(() => {
    if (event_slug) {
      if (state) {
        //   handleFormat(state);
        // } else {
        getEventDetail();
      }
    }
  }, []);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>Edit Event</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Event Title*"
            variant="outlined"
            fullWidth
            name="title"
            value={inputs.title}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
              <DesktopDatePicker
                label="Event Start Date"
                inputFormat="dd/MM/yyyy"
                value={date}
                onChange={handleChangeDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
          </LocalizationProvider>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Is Show On List? *
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="showStatus"
              value={inputs.showStatus}
              label="Is Show On List? *"
              onChange={handleChange}>
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="No Of Days *"
            variant="outlined"
            fullWidth
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            name="days"
            value={inputs.days}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Status *</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="status"
              value={inputs.status}
              label="Status *"
              onChange={handleChange}>
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Is Chat Enable?*
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="chat"
              value={inputs.chat}
              label="Is Chat Enable?*"
              onChange={handleChange}>
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Order*"
            variant="outlined"
            fullWidth
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            name="order"
            value={inputs.order}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-6">
              <p className="">Upload Image *</p>
              <FormHelperText className="pt-0">
                Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-1">
              {(images.image || oldImages.image) && (
                <img
                  src={
                    images.image ? images.image : s3baseUrl + oldImages.image
                  }
                  height="50"
                />
              )}{" "}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  name="image"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span">
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs.image.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs.image.name}</p>
          )}
        </div>
        <div className="col-12 mt-3">
          <FormControl fullWidth>
            <TextField
              id="outlined-multiline-flexible"
              label="Video Embed Code"
              multiline
              rows={6}
              name="videoUrl"
              value={inputs.videoUrl}
              onChange={handleChange}
            />
            <FormHelperText>Maximum limit 500 characters</FormHelperText>
          </FormControl>
        </div>
        <div className="col-12 mt-3">
          <FormControl fullWidth>
            <TextField
              id="outlined-multiline-flexible"
              label="Short Description *"
              multiline
              rows={6}
              name="short_description"
              value={inputs.short_description}
              onChange={handleChange}
            />
            <FormHelperText>Maximum limit 500 characters</FormHelperText>
          </FormControl>
        </div>
        <div className="col-12 mt-3">
          <h4>Detail Description *</h4>
          <TinyEditor
            setDetailDescription={setDetailDescriptionCk}
            detailDescriptionCk={detailDescriptionCk}
          />
        </div>
        <div className="text-end mt-4">
          <button onClick={handleSubmit} className="small-contained-button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
