import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { programmeListing } from "src/DAL/Programme/Programme";
import { s3baseUrl } from "../../config/config";
import {
  Avatar,
  Chip,
  CircularProgress,
  IconButton,
  TextField,
} from "@mui/material";
import moment from "moment";
import { programmeAccessApi } from "src/DAL/member/member";
import { useLocation, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DesktopDatePicker } from "@mui/lab";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITableProgram/CustomMUITable";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function ManageProgrammeAccess() {
  const params = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);
  const [program, setProgram] = React.useState([]);
  const { state } = useLocation();
  const [selected, setSelected] = useState([]);
  const programsList = async () => {
    setIsLoading(true);
    const result = await programmeListing();
    if (result.code == 200) {
      var programArray = result.program.map((item) => {
        const matchingProgram = state.program.find(
          (stateItem) => stateItem?._id?._id === item?._id
        );
        console.log(state.program, "state.program");
        console.log(state, "state");
        // item.expiry = addDays(item);
        if (matchingProgram) {
          console.log(matchingProgram, "matchingProgram");
          if (matchingProgram.expiry_date) {
            item.expiry = matchingProgram?.expiry_date;
          } else {
            if (
              state.first_payment_date == "Invalid date" ||
              state.first_payment_date == "" ||
              state.first_payment_date == null ||
              state.first_payment_date == "1970-01-01T00:00:00.000Z"
            ) {
              var result = new Date();
              result.setDate(result.getDate() + item.no_of_limited_days);
              item.expiry = result;
            } else {
              var result = new Date(state.first_payment_date);
              result.setDate(result.getDate() + item.no_of_limited_days);
              item.expiry = result;
            }
          }
        } else {
          const currentDate = moment();
          const futureDate = currentDate.add(item.no_of_limited_days, "days");
          const formattedDate = futureDate.format("YYYY-MM-DD");
          item.expiry = formattedDate;
        }
        return item;
      });

      setProgram(programArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    let programme = [];
    selected.map((item) => {
      var program_object = {
        program_id: item._id,
      };
      let find_prog = program.find((prog) => prog._id == item._id);
      if (find_prog && find_prog.program_access_type == "limited") {
        program_object.expiry_date = moment(find_prog.expiry).format(
          "YYYY-MM-DD"
        );
      }
      programme.push(program_object);
    });
    let programObject = {
      program: programme,
    };
    console.log(programObject, "programObject");
    const result = await programmeAccessApi(params.id, programObject);
    if (result.code == 200) {
      setIsLoading(false);
      navigate("/member");
      enqueueSnackbar("Member added against program successfully", {
        variant: "success",
      });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChangeDate = (date, index, row) => {
    console.log(date, "datedatedate");
    let find_index = program.findIndex((prog) => prog._id == row._id);
    let temp_state = [...program];
    let temp_element = { ...temp_state[find_index] };
    temp_element.expiry = date.$d;
    temp_state[find_index] = temp_element;
    setProgram(temp_state);
  };

  const TABLE_HEAD = [
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
      className: "p-0",
    },
    { id: "title", label: "Name", alignRight: false },
    {
      id: "status",
      label: "Status",
      type: "row_status",
    },
    {
      id: "expiry",
      label: "Expiry Date",
      type: "row_calendar",
      handleChangeDate: handleChangeDate,
    },
  ];

  // console.log(selected, "selected");
  React.useEffect(() => {
    programsList();
  }, []);
  React.useEffect(() => {
    let updatedArray = state.program.map((item, index) => {
      let obect = item._id;
      return obect;
    });
    setSelected(updatedArray);
  }, [state]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="col-12 mb-3 reply-anchor">
        <IconButton
          className="back-screen-button mb-4"
          onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
      </div>
      <div style={{ width: "100%" }}>
        <h2 className="mb-4">Manage Program Access</h2>

        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={program}
          selected={selected}
          setSelected={setSelected}
          checkbox_selection={true}
        />
      </div>
      <div className="ms-auto text-end">
        <button
          className="small-contained-button mt-3 ml-auto"
          onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </>
  );
}
