import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { Form, FormikProvider } from "formik";
import closeFill from "@iconify/icons-eva/close-fill";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
// material
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Rating,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
//
// import Scrollbar from "../../Scrollbar";
import { get_root_value } from "src/utils/domUtils";
import { htmlDecode } from "src/utils/convertHtml";
import Scrollbar from "src/components/Scrollbar";
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function VaultFilterSidebar({
  programOptions,
  categoryOptions,
  setProgramOptions,
  setCategoryOptions,
  setIsLoading,
  setFilterdata,
  onSubmitFilter,
  onCloseFilter,
  page,
  rowsPerPage,
  filterName,
  onResetFilter,
  setFilterCount,
  filterCount,
}) {
  const [programfilters, setProgramFilters] = useState([]);
  const [categoryfilters, setCategoryFilters] = useState([]);
  const handleChange = (e) => {
    const { name, checked, value } = e.target;
    if (name === "program") {
      let tempOption = programfilters.map((options) =>
        options._id === value ? { ...options, isChecked: checked } : options
      );
      setProgramFilters(tempOption);
    } else {
      let tempOption = categoryfilters.map((options) =>
        options._id === value ? { ...options, isChecked: checked } : options
      );
      setCategoryFilters(tempOption);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setProgramOptions(programfilters);
    setCategoryOptions(categoryfilters);
    const programCount = programfilters.filter(
      (program) => program?.isChecked
    ).length;
    const categoryCount = categoryfilters.filter(
      (category) => category?.isChecked
    ).length;
    const totalCount = programCount + categoryCount;
    setFilterCount(totalCount);
    localStorage.setItem("recording_filter_count", totalCount);
    let programArray = [];
    programfilters.map((program, index) => {
      program.isChecked === true ? programArray.push(program._id) : program;
    });
    let categoryArray = [];
    categoryfilters.map((category, index) => {
      category.isChecked === true ? categoryArray.push(category._id) : category;
    });
    let dataObject = {};
    if (programArray.length < 1 && categoryArray.length < 1) {
      dataObject = {};
    } else {
      dataObject = {
        program: programArray,
        vault_category: categoryArray,
      };
    }
    let filter_data = {
      page: page,
      rowsPerPage: rowsPerPage,
      filterName: filterName,
      categoryAndProgram: dataObject,
    };
    // let find_filter = localStorage.getItem("recording_list_object");
    // if (find_filter) {
    //   filter_data = JSON.parse(find_filter);
    // }
    // console.log(filter_data, "filter_data1234");

    const stringObj = JSON.stringify(filter_data);
    localStorage.setItem("recording_list_object", stringObj);
    setFilterdata(dataObject);
    onCloseFilter();
    onSubmitFilter(filter_data, dataObject);
    setIsLoading(false);
  };
  // console.log(programfilters, "programfilters");
  // console.log(programOptions, "programOptions");
  useEffect(() => {
    setProgramFilters(programOptions);
    setCategoryFilters(categoryOptions);
  }, []);
  return (
    <>
      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3 }}>
          <div className="text-center">
            {programOptions.length < 1 && categoryOptions.length < 1
              ? "No Filter Found"
              : ""}
          </div>
          {programOptions.length < 1 ? (
            ""
          ) : (
            <div>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold" }}
                gutterBottom>
                {programOptions.length < 1 ? "" : "Program"}
              </Typography>
              <FormGroup>
                {programfilters.map((programOption, index) => (
                  <FormControlLabel
                    key={programOption._id}
                    control={
                      <Checkbox
                        value={programOption._id}
                        checked={programOption?.isChecked || false}
                        name="program"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    }
                    label={htmlDecode(programOption.title)}
                  />
                ))}
              </FormGroup>
            </div>
          )}
          {categoryOptions.length < 1 ? (
            ""
          ) : (
            <div>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold" }}
                gutterBottom>
                {categoryOptions.length < 1 ? "" : "Category"}
              </Typography>
              <FormGroup>
                {categoryfilters.map((categoryOption, index) => (
                  <FormControlLabel
                    key={categoryOption.vault_title}
                    control={
                      <Checkbox
                        value={categoryOption._id}
                        checked={categoryOption?.isChecked || false}
                        name="category"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    }
                    label={htmlDecode(categoryOption.vault_title)}
                  />
                ))}
              </FormGroup>
            </div>
          )}
        </Stack>
      </Scrollbar>
      <Box sx={{ pl: 3, pr: 3, pt: 2 }}>
        <Button
          fullWidth
          size="large"
          type="submit"
          color="inherit"
          variant="outlined"
          onClick={handleSubmit}
          startIcon={
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: 5 }}>
              <path
                d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                fill={get_root_value("--portal-theme-primary")}
              />
            </svg>
          }>
          Filter
        </Button>
      </Box>
      <Box sx={{ pl: 3, pr: 3, pt: 3, pb: 1 }}>
        <Button
          fullWidth
          size="large"
          type="submit"
          color="inherit"
          variant="outlined"
          onClick={onResetFilter}
          startIcon={<Icon icon={roundClearAll} />}>
          Clear All
        </Button>
      </Box>
    </>
  );
}
