import { invokeApi } from "../../bl_libs/invokeApi";

export const JournalQuestionListingApi = async () => {
  const requestObj = {
    path: `/api/self_image_question/?module_type=journal_prompt`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getDetailSelfImage = async (id) => {
  const requestObj = {
    path: `/api/self_image_question/${id}?module_type=journal_prompt`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const SelfImageAdd = async (data) => {
  const requestObj = {
    path: `/api/self_image_question`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const AddCommentOnQuestion = async (data) => {
  const requestObj = {
    path: `/api/self_image_question/add_comment_on_self_image_question`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const SelfImageEdit = async (data, id) => {
  const requestObj = {
    path: `/api/self_image_question/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const DeleteSelfImage = async (id) => {
  const requestObj = {
    path: `/api/self_image_question/${id}?module_type=journal_prompt`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
