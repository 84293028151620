import { filter } from "lodash";
import { useEffect, useState } from "react";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Toolbar,
  Tooltip,
} from "@mui/material";
// components
import SearchNotFound from "src/components/NotFound/SearchNotFound";
import Label from "src/components/Label";
import { UserListHead, UserListToolbar } from "src/components/_dashboard/user";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import AddTax from "src/components/Tax/AddTax";
import EditTax from "src/components/Tax/EditTax";
import { deleteCountry, getCountryList } from "src/DAL/Tax/Tax";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  { id: "country", label: "Country", alignRight: false },
  { id: "countryCode", label: "Country Dialing Code", alignRight: false },
  { id: "countryIso", label: "Country ISO Code", alignRight: false },
  { id: "tax", label: "Tax %", alignRight: false },
  { id: "states", label: "States", alignRight: false },
  { id: "action", label: "Action", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.country.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.countryIso.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
const USERLIST = [
  {
    id: 1,
    country: "America",
    countryCode: "+1",
    countryIso: "US",
    tax: "10%",
    states: "10",
  },
  {
    id: 2,
    country: "Canada",
    countryCode: "+1",
    countryIso: "CA",
    tax: "15%",
    states: "15",
  },
];
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function TaxList() {
  const navigate = useNavigate();
  const pageId = useParams();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [open, setOpen] = useState(false);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [showPop, setshowPop] = useState(false);
  const [showPopEdit, setshowPopEdit] = useState(false);
  const [data, setData] = useState([]);
  const [UserId, setUserId] = useState(false);
  const [editValue, setEditValue] = useState(false);

  const handleRequestSort = (event, property) => {};

  const getCountriesList = async () => {
    setIsLoading(true);
    const result = await getCountryList();
    if (result.code === 200) {
      const countryData = result.country.map((country) => {
        return {
          id: country?._id,
          country: country?.country_name,
          countryCode: country?.dialing_code,
          countryIso: country?.iso_code,
          tax: country?.tax,
          states: country?.states?.length,
          statesData: country?.states,
        };
      });
      setUserList(countryData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleEdit = (value) => {
    console.log(value, "edit valueeeee");
    setEditValue(value);
    setshowPopEdit(true);
  };
  const handleAdd = (value) => {
    navigate(`/states/add-state`, { state: value });
  };
  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
    console.log(value, "valuevaluevaluevalue");
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    console.log("add state");
    setIsLoading(true);
    const result = await deleteCountry(deleteDoc.id);
    if (result.code === 200) {
      getCountriesList();
      // setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleOpenPopup = (row) => {
    setshowPop(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleFilterByName = (event) => {
    console.log(event.target.value, "this is value");
    setFilterName(event.target.value);
  };
  const handleNavigate = (row) => {
    navigate(`/states`, { state: row });

    // navigate(`/faqs/add-faq`);
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

  const filteredUsers = applySortFilter(
    userList,
    getComparator(order, orderBy),
    filterName
  );

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Add State",
      icon: "gala:add",
      handleClick: handleAdd,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  const isUserNotFound = filteredUsers.length === 0;
  useEffect(() => {
    getCountriesList();
  }, []);
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-sm-12">
            <h2>Tax Configuration</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button
              onClick={handleOpenPopup}
              className="small-contained-button">
              Add Country
            </button>
          </div>
        </div>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}></Stack>

        <Card style={{ overflowX: "auto" }}>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={userList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                // onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    const {
                      id,
                      country,
                      states,
                      tax,
                      countryCode,
                      countryIso,
                    } = row;
                    const isItemSelected = selected.indexOf(name) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}>
                        <TableCell align="left">
                          {page * rowsPerPage + (i + 1)}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="1">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {country}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {countryCode}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {countryIso}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {`${tax}%`}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Tooltip title="View States">
                            <div
                              className="pointer"
                              onClick={() => handleNavigate(row)}>
                              {states}
                            </div>
                          </Tooltip>
                        </TableCell>

                        <TableCell>
                          {
                            <CustomPopoverSection
                              menu={MENU_OPTIONS}
                              data={row}
                            />
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[50, 100, 150]}
            component="div"
            count={userList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <CustomPopover
          isOpenPop={showPop}
          isClosePop={setshowPop}
          title={"Add Country"}
          componentToPassDown={
            <AddTax
              setshowPop={setshowPop}
              getCountriesList={getCountriesList}
            />
          }
        />
        <CustomPopover
          isOpenPop={showPopEdit}
          isClosePop={setshowPopEdit}
          title={"Edit Country"}
          componentToPassDown={
            <EditTax
              editValue={editValue}
              setshowPopEdit={setshowPopEdit}
              getCountriesList={getCountriesList}
            />
          }
        />
      </div>
    </>
  );
}
