import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { CircularProgress, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import { addState } from "src/DAL/Tax/Tax";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AddState() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = React.useState({
    state: "",
    stateCode: "",
    stateIso: "",
    tax: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let postData = {
      state_name: inputs.state,
      dialing_code: inputs.stateCode,
      iso_code: inputs.stateIso,
      tax: inputs.tax,
      // status: true,
      country_id: state?.id ? state.id : state._id,
    };

    setIsLoading(true);
    const result = await addState(postData);
    if (result.code === 200) {
      console.log(result, "result");
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
    } else {
      console.log(result);
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
    console.log(postData, "formdata");
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  console.log(state, "stateeee");
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <div className="col-12 d-flex">
          <h2>Add State</h2>
          <p className="sub-heading ms-2 mt-1">{`(${
            state?.country ? state.country : state?.country_name
          })`}</p>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="State Name"
              variant="outlined"
              fullWidth
              required
              name="state"
              value={inputs.state}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="State Dialing Code"
                name="stateCode"
                variant="outlined"
                fullWidth
                required
                value={inputs.stateCode}
                onChange={handleChange}
              />
            </FormControl>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="State ISO Code"
                name="stateIso"
                variant="outlined"
                fullWidth
                required
                value={inputs.stateIso}
                onChange={handleChange}
              />
            </FormControl>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="Tax %"
                name="tax"
                variant="outlined"
                fullWidth
                required
                type="number"
                onWheel={(event) => event.target.blur()}
                value={inputs.tax}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
